<script>
import { required } from 'vuelidate/lib/validators'

import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers'
import service from '../../../server/http_service'

// import Layout from '../../layouts/main'
// import PageHeader from '@/components/page-header'
// import service from '../../../server/http_service'
import global_func from '@/global_function'
import i18n from '@/i18n'
import moment from 'moment'
export default {
  page: {
    title: i18n.t('login_page.login'),
    meta: [{ name: 'description' }],
  },
  components: { },
  data() {
    var lang = i18n.t('login_page')
    return {
      lang: lang,
      user_name: '',
      password: '',
      placeholder1: lang['placeholder_username'],
      placeholder2: lang['placeholder_password'],
      submitted: false,
      showDismissibleAlert: false,
      block_ui_flg: true,
      // register for dtc user
      user_name_display: '', // สำหรับแสดงบนหน้าจอ
      user_name_regiser: '', // สำหรับลงทะเบียน
      password_register: '', // สำหรับลงทะเบียน
      user_name_auto: '', // สำหรับเขา้สู่ระบบอัตโนมัติ
      password_auto: '', // สำหรับเขา้สู่ระบบอัตโนมัติ
      code_ref: '',
      email_user: '',
      email_code_input: '',
      minutesSpan: '00',
      secondsSpan: '00',
      placeholder4: lang['placeholder_username2'],
      placeholder5: lang['placeholder_password2'],
      placeholder3: 'ยืนยันรหัสผ่าน',
      placeholder_email: 'ระบุอีเมล',
      placeholder_code: 'ระบุรหัส',
      user_name_vaild: false,
      pass_vaild: false,
      pass_vaild2: false,
      user_name_vaild_text: '',
      pass_vaild_text: '',
      showDismissibleAlert2: false,
      email_register_flg: 0,
      email_register_vaild: false,
      email_code_vaild: false,
      email_code_status: 0,
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
  },
  created() {
    document.body.classList.add('auth-body-bg')
    localStorage.setItem('auth_user_data', '')
  },
  mounted() {
    this.checkAuthSingleSignOn()
  },
  validations: {
    user_name: { required },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        // eslint-disable-next-line no-undef
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          this.tryingToLogIn = true
          // Reset the authError if it existed.
          this.authError = null
          return (
            this.logIn({
              user_name: this.user_name,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToLogIn = false
                this.isAuthError = false
                // Redirect to the originally requested page, or to the home page
                this.$router.push(this.$route.query.redirectFrom || { name: 'home' })
              })
              .catch((error) => {
                this.tryingToLogIn = false
                this.authError = error ? error : ''
                this.isAuthError = true
              })
          )
        } else {
          const { user_name, password } = this
          if (user_name && password) {
            this.login({ user_name, password })
          }
        }
      }
    },
    checkUser() {
      this.showDismissibleAlert = false
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        // console.log('check user from system')
        if (localStorage.getItem('auth_user_data') === null || localStorage.getItem('auth_user_data').length === 0) {
          let data_obj = {
            user_token : '',
            oauth_provider : 'system',
          }
          localStorage.setItem('auth_user_data', data_obj)
        }
        let auth_data = localStorage.getItem('auth_user_data')
        const { user_name, password } = this
        if (user_name && password) {
          service.postService('check_user', { user_name, password, auth_data }).then((rp) => {
            // console.log(rp)
            if (rp.status == 'ok') {
              localStorage.setItem('users', JSON.stringify(rp.data))
              // let users = JSON.parse(localStorage.getItem('users'))
              // console.log(users)
              // localStorage.setItem('lang_login', JSON.stringify(i18n.t('login_page')))
              this.login({ user_name, password })
              // localStorage.setItem('priorityConfig', JSON.stringify(rp.data.priorityConfig))
              // localStorage.setItem('account_lang', rp.data.data.account_lang)
              // window.location.href = '/'
              // this.dataContact = rp.data
              // this.totalRows = this.dataContact.length

              // localStorage.removeItem('province')
              // localStorage.removeItem('trucktype')
              if (localStorage.getItem('province') === null || localStorage.getItem('province').length === 0) {
                localStorage.setItem('province', JSON.stringify(rp.province))
              }
              
              if (localStorage.getItem('trucktype') === null || localStorage.getItem('trucktype').length === 0) {
                localStorage.setItem('trucktype', JSON.stringify(rp.trucktype))
              }

            } else {
              // alert('error')
              // eslint-disable-next-line no-undef
              // reject('These credentials do not match our records.')
              this.showDismissibleAlert = true
            }
          })
        }
        // service.getService('getContact', parameter).then(rp => {
        //   this.dataContact = rp.data
        //   this.totalRows = this.dataContact.length
        // })
      }
    },
    checkUser2() {
      this.showDismissibleAlert = false
      // console.log('check user from system')
      if (localStorage.getItem('auth_user_data') === null || localStorage.getItem('auth_user_data').length === 0) {
        let data_obj = {
          user_token : '',
          oauth_provider : 'system',
        }
        localStorage.setItem('auth_user_data', data_obj)
      }
      let auth_data = localStorage.getItem('auth_user_data')
      const { user_name_auto, password_auto } = this
      if (user_name_auto && password_auto) {
        service.postService('check_user', { 
          user_name : user_name_auto, 
          password : password_auto, 
          auth_data : auth_data, 
          
        }).then((rp) => {
          // console.log(rp)
          if (rp.status == 'ok') {
            localStorage.setItem('users', JSON.stringify(rp.data))
            this.login({ user_name_auto, password_auto })
            if (localStorage.getItem('province') === null || localStorage.getItem('province').length === 0) {
              localStorage.setItem('province', JSON.stringify(rp.province))
            }
              
            if (localStorage.getItem('trucktype') === null || localStorage.getItem('trucktype').length === 0) {
              localStorage.setItem('trucktype', JSON.stringify(rp.trucktype))
            }

          } else {
            this.showDismissibleAlert = true
          }
          global_func.CloseMsg()
        })
      }
    },
    checkUserDuplicate() {
      this.showDismissibleAlert2 = false
      this.submitted = true
      
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        if (localStorage.getItem('auth_user_data') === null || localStorage.getItem('auth_user_data').length === 0) {
          let data_obj = {
            user_token : '',
            oauth_provider : 'system',
          }
          localStorage.setItem('auth_user_data', data_obj)
        }
        let auth_data = localStorage.getItem('auth_user_data')
        const { user_name, password, email_user, code_ref } = this
        if (user_name && password) {
          service
            .postService('checkUserDuplicate', {
              user_name: user_name,
              auth_data: auth_data,
            })
            .then(rp => {
              // console.log(rp)
              if(rp.status == 'ok') {
                service.postService('register_user', { user_name, password, email_user, code_ref, auth_data }).then((rp) => {
                  // console.log(rp)
                  if (rp.status == 'ok') {
                    localStorage.setItem('users', JSON.stringify(rp.data))
                    this.login({ user_name, password })
                    if (localStorage.getItem('province') === null || localStorage.getItem('province').length === 0) {
                      localStorage.setItem('province', JSON.stringify(rp.province))
                    }
                    if (localStorage.getItem('trucktype') === null || localStorage.getItem('trucktype').length === 0) {
                      localStorage.setItem('trucktype', JSON.stringify(rp.trucktype))
                    }
                  }
                })
              } else {
                this.showDismissibleAlert2 = true
              }
            })
        }
      }
    },
    checkAuthSingleSignOn() {
      let href = location.href
      // console.log(href)
      let href_sp = href.split('bh_auth=')
      let user_token = ''
      let that = this
      
      if(typeof href_sp[1] != 'undefined') {
        global_func.LoadMsg('กำลังโหลดข้อมูล','','')
        user_token = href_sp[1]
        // console.log(user_token)
        localStorage.clear()
        service
          .postService('checkAuthSingleSignOn', {
            user_token: user_token,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.status == 'success') {
              this.user_name_auto = rp.user_data.user_name
              this.password_auto = rp.user_data.password
              setTimeout(() => {
                that.checkUser2()
              }, 1500)
            } else if(rp.status == 'not_user_data'){
              let user_data = JSON.stringify(rp.user_data)
              localStorage.setItem('auth_user_data', user_data)
              this.user_name_display = rp.user_data.name_login
              global_func.CloseMsg()
              this.$bvModal.show('modal_msg_new_user')
            } else if(rp.status == 'not_found') {
              global_func.CloseMsg()
              alert('Token time out')
              window.location.href = '/'
            } else {
              global_func.CloseMsg()
              window.location.href = '/'
            }
          })
      }
    },
    checkInput() {
      let user_input = this.user_name
      let pass_input = this.password
      let pass_input2 = this.password2
      this.user_name_vaild = false
      this.pass_vaild = false
      this.pass_vaild2 = false
      let flg = 0
      if(user_input != '') {
        if(user_input.length >= 6) {
          let ck = user_input.match(/.[&,\\,/,#,+,(),$,~,%,',",:,*,?,<,>,{,},' ',]/)
          if(ck) {
            this.user_name_vaild = true
            this.user_name_vaild_text = 'ชื่อผู้ใช้ต้องไม่มีอักขระพิเศษ'
          } else {
            flg++
          }
        } else {
          this.user_name_vaild = true
          this.user_name_vaild_text = 'ชื่อผู้ใช้ไม่ถูกต้อง'
        }
      } else {
        this.user_name_vaild = true
        this.user_name_vaild_text = this.lang.req_user_mag
      }
      if(pass_input != '') {
        if(pass_input.length >= 6) {
          let ck = pass_input.match(/.[&,\\,/,#,+,(),$,~,%,',",:,*,?,<,>,{,},' ',]/)
          if(ck) {
            this.pass_vaild = true
            this.pass_vaild_text = 'รหัสผ่านต้องไม่มีอักขระพิเศษ'
          } else {
            flg++
          }
        } else {
          this.pass_vaild = true
          this.pass_vaild_text = 'รหัสผ่านไม่ถูกต้อง'
        }
      } else {
        this.pass_vaild = true
        this.pass_vaild_text = this.lang.req_pass_mag
      }
      if(pass_input2 != pass_input) {
        this.pass_vaild2 = true
        this.pass_vaild_text2 = 'รหัสผ่านไม่ตรงกัน'
      } else {
        flg++
      }
      if(flg == 3) {
        this.checkUserDuplicate()
      } else {
        return
      }
    },
    check_email() {
      if(this.email_user == '') {
        this.email_register_vaild = true
      } else {
        this.email_register_vaild = false
      }
      if (this.email_register_vaild) {
        return
      } else {
        this.email_register_vaild = false
        var email = this.email_user
        // var that = this
        service
          .postService('checkEmailDuplicate', {
            email: email,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.status == 'ok') {
              service
                .postService('checkEmail', {
                  email: email,
                })
                .then(rp => {
                  // console.log(rp)
                  if(rp.status == 'ok') {
                    this.code_ref = rp.code
                    this.email_register_flg = 1
                    this.life_time = rp.life_time
                    const deadline = new Date(this.life_time)
                    this.initializeClock(deadline)
                  } else {
                    this.email_register_vaild = true
                  }
                })
            } else {
              this.email_register_vaild = true
            }
          })
      }
    },
    check_email_code() {
      this.email_code_vaild = false
      let checktime = moment() < moment(this.life_time)
      if(checktime) {
        if(this.email_code_input == this.code_ref) {
          let email = this.email_user
          service
            .postService('checkCode', {
              email: email,
              code_input: this.email_code_input,
              life_time: this.life_time,
            })
            .then(rp => {
              // console.log(rp)
              if(rp.status == 'ok') {
                this.email_register_flg = 2
                this.email_code_input = ''
              } else {
                this.email_code_vaild = true
                this.email_code_status = 1 // status of time limit
              }
            })
        } else {
          this.email_code_vaild = true
          this.email_code_status = 0
        }
      } else {
        this.email_code_vaild = true
        this.email_code_status = 1 // status of time limit
      }
    },
    auto_register(action) {
      let action_text = action
      if(action_text == 'accept') {
        this.$bvModal.hide('modal_msg_new_user')
        global_func.LoadMsg('กำลังโหลดข้อมูล','','')
        let auth_data = localStorage.getItem('auth_user_data')
        let auth_data_obj = JSON.parse(auth_data)
        // console.log(auth_data_obj)
        service
          .postService('autoRegisterUser', {
            register_data: auth_data_obj,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.status == 'success') {
              let that = this
              this.user_name_auto = rp.data.user_name
              this.password_auto = rp.data.password
              setTimeout(() => {
                that.checkUser2()
              }, 1500)
            }
          })
      } else {
        this.$bvModal.hide('modal_msg_new_user')
        setTimeout(() => {
          window.location.href = '/'
        }, 500)
      }
    },
    open_modal_verification() {
      this.$bvModal.show('modal_verification_email')
    },
    getTimeRemaining(endtime) {
      const total = Date.parse(endtime) - Date.parse(new Date())
      const seconds = Math.floor((total / 1000) % 60)
      const minutes = Math.floor((total / 1000 / 60) % 60)
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
      const days = Math.floor(total / (1000 * 60 * 60 * 24))
  
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      }
    },
    initializeClock(endtime) {
      var that = this
      function updateClock() {
        const t = that.getTimeRemaining(endtime)

        that.minutesSpan = ('0' + t.minutes).slice(-2)
        that.secondsSpan = ('0' + t.seconds).slice(-2)

        if (t.total <= 0) {
          clearInterval(timeinterval)
        }
      }

      updateClock()
      const timeinterval = setInterval(updateClock, 1000)
    },
    checkStr (val) {
      var str = val.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/) //ตัวอักษรที่ไม่ต้องการให้มี
      if (val.indexOf('\'')!= -1) return true //เครื่องหมาย '
      if (val.indexOf('"')!= -1) return true //เครื่องหมาย "
      for (let i = 0; i < str.length; i++) {
        if (val.indexOf(str.charAt(i))!= -1) return true
      }
      return false
    },
  },
} // select * from t_member  where del_flg = '0'  and user_name = 'admin01'  and password = '123456'  order by created_at desc
</script>

<template>
  <div>
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white" />
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo DBACKHAUL-04-black.png" height="80" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-24 mt-4">{{ $t('login_page.welcome_back') }}</h4>
                        <p class="text-muted font-size-18">{{ $t('login_page.Sign_in_to_continue') }}</p>
                      </div>

                      <b-alert v-if="notification.message" variant="danger" class="mt-3" show dismissible>{{ notification.message }}</b-alert>
                      <div class="p-2 mt-5">
                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
                          {{ $t('login_page.no_have') }}
                        </b-alert>
                        <form class="form-horizontal" @submit.prevent="checkUser">
                          <div class="form-group auth-form-group-custom mb-4">
                            <img src="@/assets/images/login/user.png" class="auti-custom-input-icon" alt="logo" />
                            <label for="user_name" class="font-size-16">{{ $t('login_page.username') }}</label>
                            <input id="user_name" v-model="user_name" type="text" class="form-control" :placeholder="placeholder1" :class="{ 'is-invalid': submitted && $v.user_name.$error }" />
                            <div v-if="submitted && $v.user_name.$error" class="invalid-feedback">
                              <span v-if="!$v.user_name.required">{{ $t('login_page.req_user_mag') }}</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <img src="@/assets/images/login/padlock.png" class="auti-custom-input-icon" alt="logo" />
                            <label for="userpassword" class="font-size-16">{{ $t('login_page.password') }}</label>
                            <input id="userpassword" v-model="password" type="password" class="form-control" :placeholder="placeholder2" :class="{ 'is-invalid': submitted && $v.password.$error }" />
                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">{{ $t('login_page.req_pass_mag') }}</div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input id="customControlInline" type="checkbox" class="custom-control-input" />
                            <label class="custom-control-label font-size-16" for="customControlInline">{{ $t('login_page.remember') }}</label>
                          </div> -->

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">{{ $t('login_page.login') }}</button>
                          </div>

                          <div class="mt-4 text-center font-size-16">
                            <router-link tag="a" to="/forgot-password" class="text-muted"> <i class="mdi mdi-lock mr-1" />{{ $t('login_page.forgot') }}</router-link>
                          </div>
                        </form>
                      </div>
                      <!-- <div class="mt-5 text-center">
                        <p>
                          {{ $t('login_page.register_meg') }}<a href="/register" class="font-weight-medium text-primary">&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('login_page.register_link') }}</a>
                        </p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal_register_dtc" centered size="lg" title="ลงทะเบียนสำหรับลูกค้า DTC" title-class="font-size-24" hide-footer>
      <div>
        <div class="text-center">
          <div>
            <a href="/" class="logo">
              <img src="@/assets/images/logo DBACKHAUL-04-black.png" height="80" alt="logo" />
            </a>
          </div>
          <!-- <h4 class="font-size-24 mt-4">{{ $t('login_page.welcome_back') }}</h4> -->
          <!-- <p class="text-muted font-size-18">{{ $t('login_page.Sign_in_to_continue') }}</p> -->
          <h4 class="font-size-24 mt-4">ลงทะเบียน</h4>
          <p v-if="email_register_flg == 0" class="text-muted font-size-18">กรุณาระบุอีเมล เพื่อยืนยันตัวตน</p>
          <p v-else-if="email_register_flg == 1" class="text-muted font-size-18">กรุณากรอกรหัสลับของท่าน ที่ได้รับจากอีเมล</p>
          <p v-else-if="email_register_flg == 2" class="text-muted font-size-18">กรุณาระบุชื่อผู้ใช้งาน และรหัสผ่าน</p>
        </div>
        <template v-if="email_register_flg == 0">
          <div class="p-2 mt-3">
            <form class="form-horizontal" @submit.prevent="check_email">
              <div class="form-group auth-form-group-custom">
                <img src="@/assets/images/login/email.png" class="auti-custom-input-icon" alt="logo" />
                <label for="email_user" class="font-size-16">อีเมล</label>
                <input id="email_user" v-model="email_user" type="email" class="form-control" :placeholder="placeholder_email" :class="{ 'is-invalid': email_register_vaild }" />
              </div>
              <div v-if="email_register_vaild" class="font-color-red">
                <span>อีเมลซ้ำ หรืออีเมลไม่ถูกต้อง</span>
              </div>
              <div class="mt-4 text-center">
                <!-- <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">{{ $t('login_page.login') }}</button> -->
                <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">ตรวจสอบอีเมล</button>
              </div>
            </form>
          </div>
        </template>
        <template v-else-if="email_register_flg == 1">
          <div class="p-2 mt-3">
            <form class="form-horizontal" @submit.prevent="check_email_code">
              <div class="form-group auth-form-group-custom">
                <img src="@/assets/images/login/padlock.png" class="auti-custom-input-icon" alt="logo" />
                <label for="email_user" class="font-size-16">รหัสยืนยันตัวตน</label>
                <input id="v" v-model="email_code_input" type="text" class="form-control" :placeholder="placeholder_code" :class="{ 'is-invalid': email_code_vaild }" />
              </div>
              <div class="font-color-blue">
                <b-row>
                  <b-col>
                    <template v-if="email_code_vaild">
                      <span v-if="email_code_status == 0" class="font-color-red">รหัสไม่ถูกต้อง</span>
                      <span v-else-if="email_code_status == 1" class="font-color-red">โปรดกรอกรหัสในเวลาที่กำหนด</span>
                    </template>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <span class="font-color-blue">กรอกรหัสภายใน {{ minutesSpan }} : {{ secondsSpan }}</span>
                  </b-col>
                </b-row>
              </div>
              <div class="mt-4 text-center">
                <!-- <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">{{ $t('login_page.login') }}</button> -->
                <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">ยืนยัน</button>
              </div>
            </form>
          </div>
        </template>
        <template v-else-if="email_register_flg == 2">
          <div class="p-2 mt-3">
            <form class="form-horizontal" @submit.prevent="checkInput">
              <div class="form-group auth-form-group-custom mb-2">
                <img src="@/assets/images/login/user.png" class="auti-custom-input-icon" alt="logo" />
                <label for="user_name" class="font-size-16">{{ $t('login_page.username') }}</label>
                <input id="user_name" v-model="user_name" type="text" class="form-control" :placeholder="placeholder4" :class="{ 'is-invalid': user_name_vaild }" />
              </div>
              <div v-if="user_name_vaild" style="margin-bottom: 0.5rem;">
                <span class="font-color-red">{{ user_name_vaild_text }}</span>
              </div>
              <div v-if="showDismissibleAlert2" style="margin-bottom: 0.5rem;">
                <span class="font-color-red">{{ $t('login_page.error_or_already') }}</span>
              </div>
              <div class="form-group auth-form-group-custom mb-2">
                <img src="@/assets/images/login/padlock.png" class="auti-custom-input-icon" alt="logo" />
                <label for="userpassword" class="font-size-16">{{ $t('login_page.password') }}</label>
                <input id="userpassword" v-model="password" type="password" class="form-control" :placeholder="placeholder5" :class="{ 'is-invalid': pass_vaild }" />
              </div>
              <div v-if="pass_vaild" style="margin-bottom: 0.5rem;">
                <span class="font-color-red">
                  {{ pass_vaild_text }}
                </span>
              </div>
              <div class="form-group auth-form-group-custom mb-2">
                <img src="@/assets/images/login/padlock.png" class="auti-custom-input-icon" alt="logo" />
                <label for="userpassword" class="font-size-16">{{ $t('login_page.password') }}</label>
                <input id="userpassword" v-model="password2" type="password" class="form-control" :placeholder="placeholder3" :class="{ 'is-invalid': pass_vaild2 }" />
              </div>
              <div v-if="pass_vaild2" style="margin-bottom: 0.5rem;">
                <span class="font-color-red">
                  {{ pass_vaild_text2 }}
                </span>
              </div>
              <div class="mt-4 text-center">
                <!-- <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">{{ $t('login_page.login') }}</button> -->
                <button class="btn btn-primary w-md waves-effect waves-light font-size-16" type="submit">ลงทะเบียน</button>
              </div>
            </form>
          </div>
        </template>
        <div class="mt-5 text-center">
          <p>
            <!-- {{ $t('login_page.register_meg') }}<a href="/register" class="font-weight-medium text-primary">&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('login_page.register_link') }}</a> -->
            คุณเป็นสมาชิกแล้ว?<a href="/login" class="font-weight-medium text-primary">&nbsp;&nbsp;&nbsp;&nbsp;เข้าสู่ระบบ</a>
          </p>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal_msg_new_user" centered size="lg" title="สำหรับลูกค้า DTC" title-class="font-size-24" hide-footer>
      <div class="font-size-20">
        <b-row>
          <b-col class="d-flex justify-content-center text-center">
            <span>
              สวัสดีคุณ {{ user_name_display }}
              <br />
              สำหรับท่านที่เป็นลูกค้าของ บริษัท ดี.ที.ซี. เอ็นเตอร์ไพรส์ จำกัด
              <br />
              ท่านจะได้รับสิทธิ์ในการลงทะเบียน ทดลองใช้งานระบบ backhaul
              <br />
              โดยทางระบบ ต้องการข้อมูลของท่าน ดังนี้
              <br />
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-center font-color-blue">
            <span>
              <br />
              1. ชื่อผู้ใช้งาน
              <br />
              2. รหัสผ่าน
              <br />
              <br />
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-center text-center">
            <span>
              โดยทางระบบ ต้องการข้อมูลของท่าน เพื่อลงทะเบียนในระบบให้ท่านโดยอัตโนมัติ
            </span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col class="d-flex justify-content-center text-center">
            <span>
              * ท่านยอมรับเงื่อนไข ใช่ หรือ ไม่ ? *
            </span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col />
          <b-col class="text-center">
            <div class="d-flex justify-content-center align-items-center big_btn_blue" @click="auto_register('accept')">
              <span>
                ยอมรับ
              </span>
            </div>
          </b-col>
          <b-col>
            <div class=" d-flex justify-content-center align-items-center big_btn_red" @click="auto_register()">
              <span>
                ยกเลิก
              </span>
            </div>
          </b-col>
          <b-col />
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
